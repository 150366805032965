import cn from 'classnames';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { FC, Fragment, MouseEventHandler, useEffect, useState } from 'react';
import { preload } from 'swr';

import EmptyState from '~components/ui/EmptyState';
import Poster from '~components/ui/Poster';
import { getFromAPI } from '~utils/api/common';
import parseQuery from '~utils/api/parseQuery';
import { NormalizedBillboard } from '~utils/normalizers/normalizeBillboard';

import s from './Billboard.module.css';

const BillboardMovieDetails = dynamic(() => import('~components/Billboard/BillboardMovieDetails'), {
  ssr: false,
}) as any;

interface Props {
  movies: NormalizedBillboard[];
  date?: string;
  cinema?: string; // CinemaId
}

const Billboard: FC<Props> = ({ movies, date, cinema }) => {
  const [movieSelected, setMovieSelected] = useState<number | null>();

  const handleMovieClick =
    (id: number): MouseEventHandler<HTMLAnchorElement> =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setMovieSelected(id !== movieSelected ? id : null);
    };

  const closeMovie = () => setMovieSelected(null);

  useEffect(() => {
    if (movieSelected && typeof window !== 'undefined') {
      const element = document.getElementById(`movie-${movieSelected}`);
      element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [movieSelected]);

  if (!movies?.length) {
    return (
      <EmptyState
        type="movie"
        title="No hay películas en cartelera"
        description="En este momento no hay películas disponibles. Selecciona otra fecha o intenta más tarde."
        className="py-32"
      />
    );
  }

  const preloadMovie =
    (id: number): MouseEventHandler<HTMLAnchorElement> =>
    () => {
      preload(
        cinema ? `/movies/${id}?${parseQuery({ CinemaId: cinema, date })}` : `/movies/${id}`,
        getFromAPI,
      );
    };

  return (
    <div className={`billboard-grid ${s.root}`}>
      {movies.map((movie, index) => (
        <Fragment key={movie.id}>
          <Link
            key={movie.id}
            href={`/pelicula/${movie.slug}`}
            className={cn(s.movie, { [s.movieSelected]: movie.id === movieSelected }, 'movie')}
            onClick={handleMovieClick(movie.id)}
            id={`movie-${movie.id}`}
            prefetch={false}
            onMouseEnter={preloadMovie(movie.id)}
          >
            {movie?.poster && (
              <Poster
                data={movie.poster}
                priority={index < 6}
                sizes="(min-width: 1280px) 200px, (min-width: 640px) 160px, 64px"
              />
            )}
            <h2 className="mt-3 text-center line-clamp-2">{movie.title}</h2>
            {movie.onPremiere && <span className={s.premiereTag}>Estreno</span>}
          </Link>
          <div className={cn(s.schedule)}>
            {movie.id === movieSelected && (
              <>
                {(movie?.banner?.url || movie?.poster?.url) && (
                  <Image
                    src={movie?.banner?.url || movie?.poster?.url}
                    fill
                    className={s.background}
                    alt={movie.title}
                    sizes="64px"
                  />
                )}
                <BillboardMovieDetails
                  id={movie.id}
                  {...{ cinema, date }}
                  close={() => closeMovie()}
                />
              </>
            )}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default Billboard;
