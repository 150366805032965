import cn from 'classnames';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import s from './EmptyState.module.css';

const EmptyCard = dynamic(() => import('./EmptyCard'), { ssr: false }) as any;
const EmptyCitipremia = dynamic(() => import('./EmptyCitipremia'), { ssr: false }) as any;
const EmptyMovie = dynamic(() => import('./EmptyMovie'), { ssr: false }) as any;
const EmptySeat = dynamic(() => import('./EmptySeat'), { ssr: false }) as any;
const GeneralError = dynamic(() => import('./GeneralError'), { ssr: false }) as any;

interface Props {
  type: 'movie' | 'seat' | 'card' | 'citipremia' | 'error';
  title?: string;
  description?: string;
  className?: string;
  theme?: 'dark' | 'system';
}

const EmptyState: FC<Props> = ({ type, title, description, className, theme = 'system' }) => {
  return (
    <div className={cn(s.EmptyState, s[theme], className)}>
      {type === 'movie' && <EmptyMovie />}
      {type === 'seat' && <EmptySeat />}
      {type === 'card' && <EmptyCard />}
      {type === 'citipremia' && <EmptyCitipremia />}
      {type === 'error' && <GeneralError />}
      <h4 className="h4 mt-7 mb-2">{title}</h4>
      <p className="text-sm">{description}</p>
    </div>
  );
};

export default EmptyState;
