import Router from 'next/router';
import { UrlWithParsedQuery, format, parse } from 'url';

/**
 * Wrapper de Router.push de next.js
 *
 * @param {String|Object} path La URL a ir o un objecto de opciones
 * @param {String} path.href La URL a ir
 * @param {String} path.as Como se va a mostrar en el cliente
 * @param {Boolean} options
 */
export default function go(
  path: string | { href: string | UrlWithParsedQuery; as: string | UrlWithParsedQuery },
  options: { [opt: string]: any } = {},
): void {
  const { keepQueries = false, prefetch = true, ...nextOptions } = options;
  let { href, as } =
    typeof path === 'string'
      ? {
          href: path || undefined,
          as: path || undefined,
        }
      : path;

  if (keepQueries) {
    const { query = {} } = parse(Router.asPath, true);
    if (typeof href === 'string') href = parse(href, true);
    if (typeof as === 'string') as = parse(as, true);
    if (href && typeof href !== 'string') href.query = { ...href.query, ...query };
    if (as && typeof as !== 'string') as.query = { ...as.query, ...query };
  }

  if (href) {
    if (prefetch) {
      setTimeout(() => Router.push(href || '', as, nextOptions), 300);
      Router.prefetch(format(href));
    } else {
      Router.push(href, as, nextOptions);
    }
  }
}
