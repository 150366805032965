import cn from 'classnames';
import { FC } from 'react';

import ShadowImage, { ShadowImageProps } from '~components/ui/ShadowImage';

import s from './Poster.module.css';

const Poster: FC<ShadowImageProps> = (props) => {
  return <ShadowImage {...props} className={cn(s.root, props.className)} />;
};

export default Poster;
