interface GetOptimizedURLOptions {
  src: string;
  width: 16 | 32 | 48 | 64 | 96 | 128 | 256 | 384 | 428 | 640 | 750 | 828 | 1080 | 1200 | 1920;
  quality?: number;
}

const getNextImageSrc = ({ src, width, quality = 75 }: GetOptimizedURLOptions) =>
  `/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality}`;

export default getNextImageSrc;
