import dayjs from 'dayjs';
import { useRouter } from 'next/dist/client/router';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { getFromAPI } from '~utils/api/common';
import parseQuery from '~utils/api/parseQuery';
import { NormalizedBillboard } from '~utils/normalizers/normalizeBillboard';

interface FallbackData {
  initialBillboard: NormalizedBillboard[];
  initialDates: string[];
}

interface UseBillboardResult {
  billboard: NormalizedBillboard[];
  dates: string[];
  loading: boolean;
}

const useBillboard = ({ initialBillboard, initialDates }: FallbackData): UseBillboardResult => {
  const [billboard, setBillboard] = useState<NormalizedBillboard[]>(initialBillboard);
  const { query, replace, basePath } = useRouter();
  const { data, isValidating: loadingBillboard } = useSWR<NormalizedBillboard[]>(
    `/billboard?${parseQuery({
      ...(query?.c || query?.CinemaId ? { CinemaId: query.c || query.CinemaId } : {}),
      ...(query?.d || query?.date ? { date: query.d || query.date } : {}),
    })}`,
    {
      fetcher: (url) =>
        fetch(`${process.env.NEXT_PUBLIC_APP_URL}/api/${url}`).then((res) => res.json()),
      revalidateOnMount: false,
    },
  );
  const CinemaId = query?.c || query?.cinemaId;
  const { data: dates, isValidating: loadingDates } = useSWR(
    CinemaId ? `/shows/dates?CinemaId${CinemaId}` : null,
    {
      fetcher: (url: string) =>
        getFromAPI(url).then((response) => response.map((item: { date: string }) => item.date)),
    },
  );

  useEffect(() => {
    if (data) setBillboard(data);
  }, [data]);

  useEffect(() => {
    if (
      !!dates?.length &&
      !dates.find((item: string) => dayjs(`${query?.d || query?.date}`).isSame(dayjs(item), 'day'))
    ) {
      if (query?.d || query?.date) {
        const today = dayjs();
        const date = today.isSame(dayjs(dates[0]), 'day')
          ? today.format()
          : dayjs(dates[0]).startOf('day').format();
        replace(
          `${basePath || '/'}?${parseQuery({
            c: query.c || query.CinemaId,
            d: date,
          })}`,
        );
      }
    }
  }, [dates]);

  return {
    billboard: billboard,
    dates: dates || initialDates,
    loading: loadingBillboard || loadingDates,
  };
};

export default useBillboard;
