import classNames from 'classnames';
import ms from 'ms';
import React, { FC, HTMLProps, LegacyRef, ReactElement, useEffect, useRef, useState } from 'react';

import useGlobalState from '~utils/hooks/useGlobalState';

import s from './Carousel.module.css';

const interval = 1;
const unify = (e: MouseEvent | TouchEvent): Touch | MouseEvent =>
  (e as TouchEvent)?.changedTouches
    ? ((e as TouchEvent)?.changedTouches[0] as Touch)
    : (e as MouseEvent);

const clone = (item: ReactElement, index: number, copy = false) =>
  React.cloneElement(item, {
    className: classNames(s.item, item.props.className),
    style: { '--carousel-item-index': index },
    key: copy ? `${item.key}-copy` : item.key,
  });

interface Props extends HTMLProps<HTMLDivElement> {
  infinityLoop?: boolean;
  auto?: boolean;
  duration?: number;
  fade?: boolean;
  onMove?: (newIndex: number, oldIndex: number) => void;
  currentIndex?: number;
  onClickItem?: (item: number) => void;
}

const Carousel: FC<Props> = ({
  children,
  infinityLoop,
  className,
  duration = 3000,
  auto,
  fade,
  onMove,
  currentIndex,
  onClickItem,
  ...props
}) => {
  const [ready] = useGlobalState('ready', false);
  const [effect, setEffect] = useState<string>();
  const [time, setTime] = useState(0);
  const [enableDragEvents, setEnableDragEvents] = useState(false);
  if (!React.Children.count(children)) {
    throw Error('Carousel needs at least one child');
  }
  const x = useRef(0);
  const locked = useRef(false);
  const dragging = useRef(false);
  const item = useRef(infinityLoop ? 1 : 0);
  const el = useRef<HTMLDivElement>();
  const mainChildren: ReactElement[] = [];
  const firstChild: ReactElement[] = [];
  const lastChild: ReactElement[] = [];
  React.Children.toArray(children).forEach((item, index) => {
    if (infinityLoop) {
      const countChildren = React.Children.count(children);
      if (!index) lastChild.push(clone(item as ReactElement, countChildren + 1, true));
      if (index === countChildren - 1) firstChild.push(clone(item as ReactElement, 0, true));
    }
    mainChildren.push(clone(item as ReactElement, infinityLoop ? index + 1 : index));
  });
  const newChildren = [...firstChild, ...mainChildren, ...lastChild];
  const count = newChildren.length;
  const lastIndex = count - 1;

  const getDefaultAnimationDuration = () =>
    el.current ? ms(getComputedStyle(el.current).getPropertyValue('--carousel-duration')) : 100;

  const handleInfinityLoop = (durationFactor: number = 1) => {
    const element = el.current;
    if (item.current === 0) {
      // if first item
      item.current = lastIndex - 1;
    } else if (item.current === lastIndex) {
      // if last item
      item.current = 1;
    }

    const defaultDuration = getDefaultAnimationDuration();
    setTimeout(() => {
      locked.current = false;
      element?.classList.toggle(s.smooth, false);
      element?.style.setProperty('--carousel-duration-factor', `${1}`);
      element?.style.setProperty('--carousel-index', `${item.current}`);
    }, durationFactor * defaultDuration);
  };

  /**
   * función que se ejecuta en el evento mouse enter, y evita que el carousel sigua cambiando
   * automáticamente cuando el prop auto=true
   */
  const handleMouseEnter = () => {
    locked.current = true;
    if (!enableDragEvents) setEnableDragEvents(true);
  };

  /**
   * Se ejecuta cuando el mouse sale del carousel, es necesario para reanudar el cambio
   * automático del slide
   */
  const handleMouseLeave = () => {
    locked.current = false;
    setTime(time + 1);
  };

  /**
   * Esta función se ejecuta con el evento touchstart y mousedown es utilizada para obtener la
   * posición en 'x' del mouse o touch a demás de que evita que el carousel cambie automáticamente
   * si el prop auto es true.
   * @param e
   */
  const lock = (e: MouseEvent | TouchEvent) => {
    if (!enableDragEvents) setEnableDragEvents(true);
    // console.log(e.type);
    x.current = unify(e).clientX;
    locked.current = true;
    dragging.current = true;
    el.current?.classList.toggle(s.smooth, false);
  };

  /**
   * Esta función se ejecuta en con el evento touchend o mouseup, es utilizada para terminar el
   * movimiento que el usuario estaba ejecutando y hace una transición a la posición correcta
   * del slide
   * @param e
   */
  const move = (e: MouseEvent | TouchEvent) => {
    if (dragging.current && locked.current && (x.current || x.current === 0)) {
      const element = el.current;
      const width = el.current?.offsetWidth;
      const dx = unify(e).clientX - x.current;
      const direction = Math.sign(dx); // -1: left, 1: right, 0: none
      let factor = +((direction * dx) / (width || 1)).toFixed(2);
      const oldIndex = item.current;
      if (
        (item.current > 0 || direction < 0) &&
        (item.current < count - 1 || direction > 0) &&
        factor > 0.2
      ) {
        item.current -= direction;
        element?.style.setProperty('--carousel-index', `${item.current}`);
        factor = 1 - factor;
      }
      element?.classList.toggle(s.smooth, true);
      element?.style.setProperty('--carousel-duration-factor', `${factor}`);
      element?.style.setProperty('--carousel-tx', '0px');
      const firstOrLast = item.current === 0 || item.current === lastIndex;
      if (infinityLoop && firstOrLast) {
        handleInfinityLoop(factor);
      } else {
        locked.current = false;
      }

      dragging.current = false;

      onMove?.(item.current, oldIndex);
      x.current = 0;
    }
  };

  /**
   * Esta función se ejecuta en el evento on mousemove o touchmove
   * @param e
   */
  const drag = (e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    e.preventDefault();
    //console.log(e.type);
    if (!fade && dragging.current && (x.current || x.current === 0)) {
      el.current?.style.setProperty(
        '--carousel-tx',
        `${Math.round(unify(e).clientX - x.current)}px`,
      );
    }
  };

  const moveToIndex = (newIndex: number) => {
    locked.current = true;
    setTime(0);
    setTimeout(() => {
      locked.current = false;
    }, interval);
    el.current?.classList.toggle(s.smooth, true);
    const oldIndex = item.current;
    item.current = newIndex;
    el.current?.style.setProperty('--carousel-index', `${item.current}`);
    if (item.current === 0 || item.current === lastIndex) {
      handleInfinityLoop();
    }
    onMove?.(item.current, oldIndex);
  };

  const clickHandler = () => {
    onClickItem?.(item.current);
  };

  useEffect(() => {
    if (currentIndex && currentIndex !== item.current) {
      moveToIndex(currentIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (auto) {
      setTimeout(() => {
        if (!locked.current) {
          if (time <= duration) {
            setTime(time >= 0 ? time + interval : 0);
          } else {
            moveToIndex(item.current + 1);
          }
        } else {
          // setTime(time - interval);
        }
      }, interval);
    }
  }, [time, auto]);

  useEffect(() => {
    setEffect(fade ? 'fade' : 'swipeLeft');
  }, [fade]);

  useEffect(() => {
    if (el.current && ready) {
      el.current.style.setProperty('--carousel-items', `${count}`);
      el.current.style.setProperty('--carousel-index', `${item.current}`);
      el.current.addEventListener('mouseenter', handleMouseEnter, { passive: true });
      el.current.addEventListener('touchstart', lock, { passive: true });
    }
    return () => {
      if (el.current && ready) {
        el.current.removeEventListener('mouseenter', lock, false);
        el.current.removeEventListener('touchstart', lock, false);
      }
    };
  }, [ready]);

  useEffect(() => {
    if (el.current) {
      el.current.addEventListener('mousedown', lock, false);
      el.current.addEventListener('mousemove', drag, false);
      el.current.addEventListener('touchmove', drag, { passive: false });
      el.current.addEventListener('mouseup', move, false);
      el.current.addEventListener('touchend', move, false);
      el.current.addEventListener('mouseleave', handleMouseLeave, false);
      el.current?.addEventListener('click', clickHandler as any);
      return () => {
        if (el.current) {
          el.current.removeEventListener('mousedown', lock, false);
          el.current.removeEventListener('mousemove', drag);
          el.current.removeEventListener('touchmove', drag);
          el.current.removeEventListener('mouseup', move, false);
          el.current.removeEventListener('touchend', move, false);
          el.current.removeEventListener('mouseleave', handleMouseLeave, false);
          el.current.removeEventListener('click', clickHandler as any);
        }
      };
    }
  }, [enableDragEvents]);

  return (
    <div
      className={classNames(s.root, s[effect || 'swipeLeft'], className)}
      ref={el as LegacyRef<HTMLDivElement>}
      {...props}
    >
      <div className={s.wrapper}>{newChildren}</div>
    </div>
  );
};

export default Carousel;
