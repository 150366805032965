const isTouchScreen = () => {
  if (typeof window !== 'undefined') {
    return (
      // @ts-ignore
      'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    );
  }
  return true;
};

export default isTouchScreen;
