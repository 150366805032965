import classNames from 'classnames';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import s from './Loading.module.css';

interface Props {
  show?: boolean;
  label?: ReactNode;
  size?: 'normal' | 'large' | 'small';
  className?: string;
  containerClassName?: string;
  theme?: 'light' | 'dark' | 'default';
}

const Loading: FC<PropsWithChildren<Props>> = ({
  show,
  label,
  children,
  className,
  size,
  containerClassName,
  theme = 'default',
}) => {
  // if (!show) return <>{children}</>;
  if (React.Children.count(children)) {
    return (
      <div
        className={classNames(
          s.root,
          s[theme],
          s[size || 'normal'],
          { [s.visible]: show },
          containerClassName,
        )}
      >
        {children}
        <span className={classNames(s.block, s[theme], className)}>
          <LoadingOutlined />
          <span>{label}</span>
        </span>
      </div>
    );
  }
  return show ? (
    <div
      className={classNames(
        s.root,
        s.inline,
        s[theme],
        s[size || 'normal'],
        { [s.visible]: show },
        containerClassName,
        className,
      )}
    >
      <LoadingOutlined /> <span>{label}</span>
    </div>
  ) : null;
};

export default Loading;
